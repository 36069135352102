import React, { useState, useEffect } from 'react'
import { Pagination } from 'rsuite';
import DataTable from './DataTable'

// redux
import { 
  useSelector, 
  useDispatch 
} from 'react-redux'

import { 
  getOptions, 
  getContextOptions, 
  getPerPage, 
  getTableOptions, 
  getYou 
} from '../redux/options/selectors' 

import { getFastest } from '../redux/data/selectors' 
import { setOption, setTableOption } from '../redux/options/actions' 
import groupTypes from '../redux/options/groups'
import CarModal from './CarModal';
import CarLogo from './CarLogo';

// Sortable table for displaying car data
const SortTable = ( props ) => {

  const { 
    data = [], 
    loading = false, 
    modifyColumns = null, 
    column_options = {}, 
    context = '', 
    rowClassName = null,
    // allow row click
    clickRow = true,
    // allow column sort
    columnSort = true,
    ...rest
  } = props
  
  // state
  const [ page, setPage ] = useState( 1 )
  const [ clickedRow, setClickedRow ] = useState( null )
  const [ showModal, setShowModal ] = useState( false )

  // redux
  const dispatch = useDispatch()
  const you = useSelector( getYou )
  const fastest = useSelector( getFastest )
  const { car = '', group = '', penalty = '' } = getContextOptions( useSelector( getOptions ), context )
  const { sortColumn = 'time', sortOrder = 'desc' } = useSelector( getTableOptions )
  const perPage = useSelector( getPerPage )

  // dispatchers
  const setSortColumn = value => dispatch( setTableOption( 'sortColumn', value ) )
  const setSortOrder = value => dispatch( setTableOption( 'sortOrder', value ) )

  // save settings sorting changes
  useEffect( () => {
    dispatch( setOption( groupTypes.TABLE, { sortColumn, sortOrder } ) )
  }, [ sortColumn, sortOrder ] )

  // reset page when one of the filter values changes
  useEffect( () => {
    setPage( 1 )
  }, [ group, car, penalty ] )

  // default columns for table
  const columns = [
    { 
      name: 'cat', 
      label: 'Group',
      icon: 'hashtag',
      order: 'asc',
      show: false
    },
    {
      name: 'pos', 
      label: 'Position',
      icon: 'star',
      type: 'num',
      order: 'asc',
      show: !car
    },
    {
      name: 'car', 
      label: 'Car',
      icon: 'car',
      type: '',
      order: 'asc',
      show: !car
    },
    { 
      name: 'laptime',       
      label: 'Laptime',
      icon: 'stopwatch',
      order: 'asc',
      show: true
    },
    {
      name: 'diff',       
      label: 'Improvement',
      icon: 'charts-line',
      order: 'asc',
      show: false
    },
    { 
      name: 'penalty', 
      label: 'Cones',
      icon: 'cone',
      type: 'num',
      order: 'asc',
      show: true
    },
    { 
      name: 'lap', 
      label: 'Lap',
      icon: 'flag',
      type: 'num',
      order: 'asc',
      show: car
    },
    { 
      name: 'time', 
      label: 'Recorded',
      icon: 'calendar',
      order: 'desc',
      show: true
    }
  ]

  // sort data
  const sortData = ( rows ) => 
  {
    // sorting
    if ( sortColumn && sortOrder ) 
    {
      const column = columns.filter( c => c.name === sortColumn )[ 0 ],
            type = column.hasOwnProperty( 'type' ) ? column.type : ''

      return rows.sort( ( a, b ) => 
      {
        let x = a[ sortColumn ],
            y = b[ sortColumn ],
            order = 0

        if ( type === 'num' )
        {
          // sort numerical
          x = x * 1 
          y = y * 1
          // ignore non numericals
          if ( isNaN( x ) ) 
          {
            order = 999
          }
          else if ( isNaN( y ) )
          {
            order = -999
          }
          else
          {
            order = x - y 

            // flip order
            if ( order !== 0 && sortOrder === 'desc' ) 
            {
              order *= -1
            }
          }          
        }
        else
        {
          // sort string
          if ( x > y ) order = 1
          if ( x < y ) order = -1
          // flip order
          if ( sortOrder === 'desc' ) 
          {
            order *= -1
          }
        }

        return order
      } )
    }

    return rows
  }

  // paginate data
  const paginateData = ( rows ) => (
    rows.filter( ( v, i ) => {
      const start = perPage * ( page - 1 )
      const end = start + perPage
      return i >= start && i < end
    } )
  )

  // get data with sorting and pagination ( in that order ), 
  // optionally modify rows
  const getData = () => paginateData( columnSort ? sortData( data ) : data )

  // apply state change on column sort
  const handleSortColumn = ( name, order = '' ) => 
  {    
    order = sortOrder
    if ( name === sortColumn )
    {
      order = sortOrder === 'asc' ? 'desc' : 'asc'
      // only change sort order when column is unchanged
      setSortOrder( order )
    }
    else
    {
      const column = columns.filter( c => c.name === name )[ 0 ]
      // new column
      setSortColumn( name )
      // apply columns default sort order
      order = column.hasOwnProperty( 'order' ) ? column.order : 'asc'
      setSortOrder( order )
    }    
  }

  // render empty
  const renderEmpty = () => (
      loading
      ? <p className="empty-data">Loading ...</p>
      : <p className="empty-data">No entries found</p>
  )

  // format row classname
  const _rowClassName = ( row = null, className = '' ) => 
  {
    if ( row )
    {
      if ( you && car !== you && row.car && String( row.car ).toUpperCase() === String( you ).toUpperCase() )
      {
        className += ' active'
      }

      if ( row.pos && row.pos !== '-' )
      {
        className += ' pos-' + row.pos
      }

      if ( row.penalty && row.penalty > 0 )
      {
        className += ' penalty'
      }

      if ( row.comment && row.comment !== '' )
      {
        className += ' comment'
      }

      if ( fastest && row.laptime === fastest.laptime )
      {
        className += ' fastest'
      }
    }
    
    // maybe filter row className
    if ( typeof rowClassName === 'function' )
    {
      className = rowClassName( row, className + ' ' )
    }

    // maybe add className
    if ( typeof rowClassName === 'string' )
    {
      className += ' ' + rowClassName
    }

    // return the className
    return className
  }

  // format column value
  const colValue = ( col, value, row ) =>
  {
    // format penalty or return dash
    if ( 'penalty' === col.name ) 
    {
      // show an X on disqualified rounds when the penalty is zero
      if ( row.comment !== '' && row.penalty === 0 )
      {
        return 'X'
      }
      // otherwise just show the penalty
      return value > 0 ? value/5 : '-'
    }

    // format time value, skip seconds
    if ( 'time' === col.name ) 
    {
      const parts = value.split( ':' )
      while ( parts.length > 2 ) parts.pop()
      return parts.join( ':' )
    }

    if ( 'car' === col.name )
    {
      return (
        <React.Fragment>
          <CarLogo brand={ row.model } title={ row.model }/>{ value }
        </React.Fragment>
      )
    }

    // return value by default
    return value
  }

  // get pagination component
  const getPaging = () =>{
    // get the number of pages
    const pages = Math.ceil( data.length / perPage )
    // don't render if only one page
    if ( pages <= 1 ) return null
    // return the pagination component
    return <Pagination 
      prev
      first
      last
      next
      activePage={ page }
      pages={ pages }
      onSelect={ setPage }
      maxButtons={ 4 }
      size="md"
    />
  }

  // apply column options
  const applyColumnOptions = columns => columns.map( c => (
    {
      ...c,
      show: column_options.hasOwnProperty( c.name ) ? column_options[ c.name ] : c.show
    }
  ) )
  
  // maybe modify columns
  const mod_columns = applyColumnOptions( modifyColumns ? modifyColumns ( columns ) : columns )

  // show modal
  const onClickRow = row => 
  {
    if ( clickRow )
    {
      setClickedRow( row )
      setShowModal( true )
    }
  }

  // hide modal
  const onHideModal = () => setShowModal( false )

  return (
    <React.Fragment>

      <CarModal show={ showModal } data={ clickedRow } onClose={ onHideModal } />

      { getPaging() }
    
      <DataTable
        className="timing-table"
        rows={ getData() }
        columns={ mod_columns.filter( column => column.show ) }
        sortColumn={ sortColumn }
        sortOrder={ sortOrder }
        onSortColumn={ columnSort ? handleSortColumn : null }
        rowClassName={ _rowClassName }
        renderEmpty={ renderEmpty }
        colValue={ colValue }
        onClickRow={ clickRow ? onClickRow : null }
        { ...rest }
      />

      { getPaging() }

    </React.Fragment>
  )
}

export default SortTable