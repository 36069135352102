// debugging slows down the interval and the number of rows to retrieve per call
const debug = false

// root domain
export const dataUrl = 'https://autoproef.nl/'

//  api url
export const apiUrl = dataUrl + 'api/'

// max rows to retreve, for debugging only
export const maxRows = debug ? 30 : 0

// poll delay in ms; 5 seconds
export const pollDelayShort = 5 * 1000

// long poll delay is used when cached results are retrieved (a really long time)
export const pollDelayLong = 99 * 60 * 1000

// time notification is displayed
export const notificationTime = 30 * 1000

// maximum number of notifications displayed
export const notificationsMax = 3