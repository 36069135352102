import React from 'react'
import { Toggle } from 'rsuite'

const LabeledToggle = ( { className = '', children = [], checked = false, disabled = false, onChange = null, name = '' } ) =>
{
  const onClick = ( e ) =>
  {
    if ( onChange ) 
    {
      onChange( name, !checked )
    }

    // stop here
    e.preventDefault()
  } 

  let classNames = 'labeled-toggle ' + className
  if ( disabled ) classNames += ' disabled'
  if ( checked ) classNames += ' checked'

  return (
    <div className={ classNames } onClick={ onClick }>
      <div className="label">{ children }</div>
      <Toggle checked={ checked }/>
    </div>
  )
}

export default LabeledToggle