import { createSelector } from 'reselect'
import groupTypes from './groups'

// get all options from state
const getAllOptions = ( { options } ) => options

/**
 * Compare two types
 * @param {*} a 
 * @param {*} b 
 * @returns 
 */
const compareTypes = ( a, b ) =>
{
  // skip undefined values
  if ( a === undefined ) return true

  // ignore compare if default is null
  if ( b === null ) return true

  // null values always compare as false
  if ( a === null ) return false

  const atype = typeof a,
        btype = typeof b

  // works for all but objects
  if ( atype !== btype ) return false

  // check arrays
  if ( Array.isArray( atype ) && !Array.isArray( btype ) ) return false
  if ( Array.isArray( btype ) && !Array.isArray( atype ) ) return false

  // return true in all other cases
  return true
}

/**
 * Get a single option, compare types too
 * @param {*} options 
 * @param {*} key 
 * @param {*} value 
 * @returns 
 */
const getOption = ( options, key, value = null ) => 
{
  return options && 
         options.hasOwnProperty( key) && 
         compareTypes( options.key, value ) ? options[ key ] : value
}

/**
 * Get options from a certain context (= a deeper nested level in options)
 * @param {*} options 
 * @param {*} context 
 * @param {*} fallback 
 * @returns 
 */
export const getContextOptions = ( options, context = '', fallback = {} ) =>
{
  if ( !context ) return options  
  return options && options.hasOwnProperty( context ) ? options[ context ] : fallback
}

// get all options
export const getOptions = createSelector(
  [ getAllOptions ],
  ( options ) => options ? options : {}
)

// get notification options
export const getNotificationOptions = createSelector(
  [ getAllOptions ],
  ( options ) => getOption( options, groupTypes.NOTIFY )
)

// get display options
export const getDisplayOptions = createSelector(
  [ getAllOptions ],
  ( options ) => getOption( options, groupTypes.DISPLAY )
)

// get per page option
export const getPerPage = createSelector(
  [ getAllOptions ],
  ( options ) => getOption( options, 'perPage', 20 )
)

// get you option
export const getYou = createSelector(
    [ getAllOptions ],
    ( options ) => getOption( options, 'you', '' )
)

// get asked you
export const getAskedYou = createSelector(
  [ getAllOptions ],
  ( options ) => getOption( options, 'askedYou', false )
)

// get filter options
export const getFilters = createSelector(
  [ getAllOptions ],
  ( options ) => getOption( options, groupTypes.FILTERS, {} )
)

// get car filter option
export const getCarFilter = createSelector(
  [ getFilters ],
  ( filters ) => filters.car
)

// get group filter option
export const getGroupFilter = createSelector(
  [ getFilters ],
  ( filters ) => filters.group
)
// get penalty filter option
export const getPenaltyFilter = createSelector(
  [ getFilters ],
  ( filters ) => filters.penalty
)

// get home page options
export const getHomePage = createSelector(
  [ getAllOptions ],
  ( options ) => getOption( options, groupTypes.HOMEPAGE, {} )
)

// get group page options
export const getGroupPage = createSelector(
  [ getAllOptions ],
  ( options ) => getOption( options, groupTypes.GROUPPAGE, {} )
)

// get car page options
export const getCarPage = createSelector(
  [ getAllOptions ],
  ( options ) => getOption( options, groupTypes.CARPAGE, {} )
)

// get table options
export const getTableOptions = createSelector(
  [ getAllOptions ],
  ( options ) => getOption( options, groupTypes.TABLE, {} )
)

// get fastest time
export const getFastest = createSelector(
  [ getAllOptions ],
  ( options ) => getOption( options, groupTypes.FAST, {} )
)