import React from 'react'
import Chart from 'react-google-charts'
import { useSelector } from 'react-redux'
import { getDisplayOptions } from '../redux/options/selectors' 
import { ms2laptime, laptime2ms } from '../tools/Time'

const TimeChart = ( { rows = [], max_rows = 20 } ) =>
{
  // get display options
  const display = useSelector( getDisplayOptions )

  // stop righ away if uses doesn't want to show the time graph
  if ( !display.time_graph ) return null

  // stop here if there's no data
  if ( !rows || rows.length === 1 ) return null

  // first, sort by lap
  rows.sort( (a , b) => b.lap - a.lap )

  // y-axis labels, we only use min and max value
  let label_min = { v: 99999999999, f: '' },
      label_max = { v: 0, f: '' }  ,
      total = 0   
      
  // sort rows on lap number (or time)
  rows.sort( ( a, b ) => a.lap - b.lap )

  // next, remove all but lap & time, convert time to ms as value
  // seperate v(alue) and f(ormatted value)
  const laptimes = rows.map( ( row, index ) => 
  {
    // conver time to int, otherwise the chart doesn't get it
    const value = laptime2ms( row.laptime )

    // set min label
    if ( label_min.v > value )
    {
      label_min = { v: value, f: row.laptime }
    }

    // set max label
    if ( value > label_max.v )
    {
      label_max = { v: value, f: row.laptime }
    }

    // increment total value
    total += value

    // return the formatted value
    return [ 
      { v: index + 1, f: 'Lap ' + ( index + 1 ) }, 
      { v: value, f: row.laptime } 
    ]
  } )

  if ( !laptimes || laptimes.length <= 1 ) return null

  // add header row
  const header = [
    'Lap',
    'Laptime',
  ]

  // maybe limit number of rows to show
  const sliced = max_rows ? laptimes.slice( -1 * Math.min( max_rows + 1, laptimes.length ) ) : laptimes

  // bail if no data remains
  if ( !sliced || sliced.length <= 1 ) return null

  // put it together
  const data = [
    ...[ header ],
    ...sliced
  ]

  // show median / average time as label
  const //med_time = ( label_min.v + label_max.v ) / 2,
        avg_time = total / laptimes.length,
        //label_med = { v: med_time, f: ms2laptime( med_time ) },
        label_avg = { v: avg_time, f: ms2laptime( avg_time ) }

  return (
    <div className="linechart chart time-chart">
      <Chart 
        width={ '100%' }
        height={ '30%' }
        chartType="LineChart"
        options={ { 
          colors: [ 'red' ],
          legend: 'none',
          pointSize: 3,
          vAxis: { 
            ticks: [ label_min, label_avg, label_max ],
            gridlines: {
              color: '#999'
            }
          },
          hAxis: { 
            ticks: sliced.map( v => v[ 0 ].v ),
            gridlines: {
              color: '#eee'
            } 
          },
          chartArea: { 
            left: 60,
            top: 10, 
            right: 10,
            bottom: 25
          }
        } }
        data={ data }
        // data={ data.slice( -1 * Math.min( max_rows + 1, data.length ) ) } 
      />
    </div>
  )
}

export default TimeChart