import React, { useState } from 'react'
import { InputGroup, Input } from 'rsuite'
import Icon from './Icon'

// redux
import { useSelector, useDispatch } from 'react-redux'
import { getYou } from '../redux/options/selectors' 
import { setOption } from '../redux/options/actions' 


// user car number form
const YouForm = ( { onChange, onClose } ) =>
{  
  // state
  const you = useSelector( getYou )
  const [ value, setValue ] = useState( you )
  const dispatch = useDispatch()
  
  // listen for enter key
  const onKey = ( e ) => 
  {
    if ( e.key === 'Enter' ) 
    {
      onSave()
    }
  }

  // save 
  const onSave = () => {
    if ( onChange ) onChange( String( value ).toUpperCase() )    
    dispatch( setOption( 'you', value ) )
    if ( onClose ) onClose()
  }

  // only pass numerical values with max of 4 digits
  const onChangeValue = ( value ) => 
  {    
    const num = String( value.match( /\d+/g ) ).substring( 0, 4 )
    setValue( num !== 'null' ? num : '' )
  }

  return (
    <React.Fragment>
      <InputGroup>
        <Input placeholder="Please enter a registration number" length="4" onChange={ onChangeValue } value={ value } onKeyDown={ onKey }></Input>
        <InputGroup.Button  onClick={ onSave }>
          <Icon icon="check"/> Ok
        </InputGroup.Button>
      </InputGroup>
      <p className="help">Enter a registration number to highlight it in the lists and graphs.</p>
      
    </React.Fragment>
  )
}

export default YouForm