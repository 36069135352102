import React from 'react'
import Icon from './Icon'
import DataTableRow from './DataTableRow'

const DataTable = ( props ) =>
{
  const {
    // css class name
    className = '',
    // colums or headers = { name: '', label: '' }
    columns = [],
    // rows or data
    rows = [],
    // sorting params
    sortColumn = '',
    sortOrder = '',
    // click events
    onSortColumn = null,
    onClickRow = null,
    // sort icons
    sortUpIcon = 'arrow-up',
    sortDownIcon = 'arrow-down',
    // row class name, can be a method!
    rowClassName = '',
    // format row value
    colValue = null
  } = props

  // build classnames
  let classNames = className +' data-table'
  // add sortable class when the is a sorting method
  classNames += onSortColumn ? ' sortable' : ''
  // add clickable class when rows are clickable
  classNames += onClickRow ? ' clickable' : ''

  // get sort icon
  const getSortIcon = ( column ) => {
    if ( !onSortColumn ) return null
    let className = 'sort '
    if ( sortColumn === column )
    {
      className += sortOrder ? sortOrder : 'asc'
    }
    return (
      <div className={ className }>
        <Icon icon={ sortUpIcon } className='desc'/>
        <Icon icon={ sortDownIcon } className='asc'/>
      </div>
    )
  }

  // get rowClassName, might be a function 
  const getRowClassName = ( row ) => {
    // className can be a function
    let className = typeof rowClassName === 'function' ? rowClassName( row, '' ) : rowClassName
    // add recent class when row is recent
    className += row.recent ? ' recent' : ''
    // return the className
    return className
  }

  // get a single row
  const getRow = ( row ) => (
    <DataTableRow 
      key={ row.id } 
      row={ row } 
      columns={ columns } 
      colValue={ colValue }
      onClick={ () => onClickRow ? onClickRow ( row ) : null }
      className={ getRowClassName( row ) }
    />
  )

  return(
    <table className={ classNames } data-sort={ sortColumn } data-order={ sortOrder } >
      <thead>
        <tr>
          { 
            columns.map( ( column, c ) => (
              <th key={ `col-${c}` } data-name={ column.name } onClick={ () => onSortColumn ? onSortColumn( column.name ) : null }>
                { column.icon ? <Icon icon={ column.icon } className="icon"/> : null }
                <span className="label">{ column.label }</span>
                { getSortIcon( column.name ) }
              </th>
            ) ) 
          }
        </tr>
      </thead>
      <tbody>
        {
          rows.map( row => getRow( row ) )
        }
      </tbody>
    </table>
  )
}

export default DataTable