import React from 'react'

// components
import GroupDropdown from '../components/GroupDropdown'
import CarDropdown from '../components/CarDropdown'
import PenaltyDropdown from '../components/PenaltyDropdown'
import ResetFiltersButton from '../components/ResetFiltersButton'
import FilterTable from '../components/FilterTable'
import { ButtonToolbar, Container, Header, Content } from 'rsuite';
import ScatterChart from '../components/ScatterChart'

// home page
const HomePage = ( { context = 'homepage' } ) => {
  
  // set column options
  const column_options = {
    pos: true
  } 

  return (
    <Container className={ context }>
      <Header>
        <ButtonToolbar className="filters">
          <GroupDropdown context={ context }/>
          <CarDropdown context={ context }/>
          <PenaltyDropdown context={ context }/>
          <ResetFiltersButton context={ context }/>   
        </ButtonToolbar>
      </Header>
      <Content>        
        <FilterTable 
        context={ context } 
        column_options={ column_options }
        between={ <ScatterChart/> }/>
      </Content>
    </Container>
  )
}

export default HomePage