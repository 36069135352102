import React, { useEffect } from 'react'

// components
import { SelectPicker } from 'rsuite'
import Icon from './Icon'

// redux
import { useSelector, useDispatch } from 'react-redux'
import { getOptions, getContextOptions, getYou } from '../redux/options/selectors' 
import { setOption } from '../redux/options/actions' 
import { getCarsDetails } from '../redux/data/selectors' 
import CarLogo from './CarLogo';

const CarDropdown = ( { showAll = true, context = 'filters', onChange = null } ) =>
{
  // redux
  const dispatch = useDispatch()  
  // try to get filters from context
  const { group = '', car = ''  } = getContextOptions( useSelector( getOptions ), context )
  // other selectors
  const cars = useSelector( getCarsDetails)
  const you = useSelector( getYou )

  // dispatchers
  const onSelect = value => 
  {
    dispatch( setOption( 'car', value, context ) )
    if ( onChange ) onChange( value )
  }
  
  // reset car when choosing a different group
  useEffect( () =>
  {
    // optionally reset car dropdown if it doesn't belong to the selected group
    if ( car && group && !group_cars.includes( car ) )
    {
      dispatch( setOption( 'car', '', context ) )
    }
  }, [ car, group ] ) 

  // get car ids based on group
  const group_cars = group 
    ? Object.values( cars ).filter( car => car.group === group ) 
    : Object.values( cars )

  // add show all option
  if ( showAll ) group_cars.unshift( 'All cars' )

  // render the selected value with the car icon in front
  const renderValue = ( value ) =>
  {
    return (
      <React.Fragment>
        <Icon icon="car" className={ car ? 'active' : '' }/> { `Car ${value}` } <Icon icon="arrow-down" className="carret"/>
      </React.Fragment>
    )
  }

  // render the menu items, add a class to the current one / you one
  const renderMenuItem = ( label, item ) =>
  {
    if ( String( item.value ) === String( you ) )
    {
      return <div className='you'>{ label } <Icon icon="avatar" /></div>      
    }
    return <React.Fragment>{ label }</React.Fragment>
  }

  const getData = () => {
    return group_cars.map( item =>
    { 
      if ( typeof item === 'string' ) 
      {
        return { value: '', label: item }
      }
      
      return { 
        value: item.num, 
        label: <React.Fragment><CarLogo brand={ item.model }/><span className="num">{ item.num }</span></React.Fragment> 
      }
    } )
  }

  // render the dropdown
  return (
    <SelectPicker
      className="filter-car"
      data={ getData() }
      appearance="custom" // throws a warning but works to force custom layout
      placement="auto"
      placeholder={ renderValue( '' ) }
      renderValue={ renderValue }
      renderMenuItem={ renderMenuItem }
      cleanable={ false }
      onChange={ onSelect }
      value={ car }
      //noCaret={ true }
    />
  )
}

export default CarDropdown