import React from 'react'
import { Nav } from 'rsuite'
import Icon from './Icon'
import { Link, useLocation } from 'react-router-dom'

const NavLink = ( { icon, children, href, ...rest } ) =>
{
  const location = useLocation();
  const active = ( location.pathname.indexOf( href ) === 0 && href !== '/' ) || 
                 ( location.pathname === '/' && href === '/' ); 

  return (
    <Nav.Item 
      active={ active } 
      to={ href } // use to instead of href because of Link
      as={ Link }
      icon={ icon ? <Icon icon={ icon } /> : null } 
      { ...rest }
      >
      <span className="label">{ children }</span>
    </Nav.Item>
  )
}

export default NavLink