import React from 'react'
import Icon from './Icon'

// known brands
const brands = [
  'abarth',
  'acura',
  'alfa-romeo',
  'alpine',
  'austin',
  'audi',
  'bmw',
  'chevrolet',
  'chrysler',
  'classic-mini',
  'mini-classic',
  'citroen',
  'dacia',
  'ferrari',
  'fiat',
  'ford',
  'fury',
  'fisher',
  'honda',
  'jaguar',
  'jeep',
  'kia',
  'lamborghini',
  'land-rover',	
  'lexus',
  'mazda',
  'mercedes',  
  'mg',
  'mini',
  'minicooper',
  'mitsubishi',
  'mnr',
  'nissan',
  'nsu',
  'opel',
  'peugeot',
  'porsche',
  'renault',
  'seat',
  'skoda',
  'smart',
  'subaru',
  'suzuki',
  'sylva',
  'toyota',
  'triumph',
  'vauxhall',
  'volvo',
  'vw',
  'volkswagen',
  'westfield'
]

// try to guess the brand from a string
const getBrandFromString = ( str = '' ) =>
{  
  if ( str )
  {
    // clean up string by removing spaces and making it lowercase
    const text = str.trim().toLowerCase().replace( /\s/g,'-' )
    const matches = []
    for ( const brand of brands )
    {      
      if ( text.indexOf( brand ) > -1 )
      {
        // otherwise add it to the list of possible matches
        matches.push( brand )
      }
    }

    // return the first 'next best' match if we have any
    if ( matches )
    {
      // sort on length
      matches.sort( ( a, b ) => b.length - a.length )
      // return the longest match
      return matches[ 0 ]
    }
  }
  return ''
}

// check for existing logo and return logo if so, null if brand isn't known
const CarLogo = ( { brand = '', fallback = false, className = '', ...rest } ) => 
{  
  if ( brand )
  {
    const b = brand
    // try to guess the brand if we don't have an exact match
    brand = getBrandFromString( brand )

    // add car-logo class
    className += ' car-logo'
    
    // return logo if brand is known
    if ( brand )
    {
      return <Icon className={ className } icon={ brand } { ...rest }/>
    }
    else
    {
      console.debug( 'Unknown brand:', b )
    }
  }

  return fallback ? fallback : false
}

export default CarLogo