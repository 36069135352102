import { createSelector } from 'reselect'
import { getUnique, numSort } from '../../tools/Array'
import { getGroupFilter } from '../options/selectors'

// get data from state
const getAllData = ( { data } ) => Array.isArray( data ) ? data : []

// get data
export const getData = createSelector(
  [ getAllData ],
  ( data ) => data
)

// get unique groups
export const getGroups = createSelector(
  [ getAllData ],
  ( data ) => getUnique( data, 'cat' )
)

// get unique car ids
export const getAllCars = createSelector(
  [ getAllData ],
  ( data ) => getUnique( data, 'car' )
)

// get unique car ids, take group into account
export const getGroupCars = createSelector(
  [ getAllData, getGroupFilter ],
  ( data, group ) => getUnique( group ? data.filter( d => d.cat === group ) : data, 'car' )
)

// get unique car ids with groups
export const getCarsAndGroups = createSelector(
  [ getAllData, getAllCars ],
  ( data, cars ) => {
    const out = {}
    for( const car of cars )
    {
      const dcar = data.find( d => d.car === car )
      if ( dcar ) 
      {
        // get car => group / category
        out[ car ] = dcar.cat
      }
    }
    return out
  }
)

// get unqiue car ids with details ( model & group )	
export const getCarsDetails = createSelector(
  [ getAllData, getAllCars ],
  ( data, cars ) => {
    const out = {}
    for( const car of cars )
    {
      const dcar = data.find( d => d.car === car )
      if ( dcar ) 
      {
        // get number, category and model
        out[ car ] = {
          num: car,
          group: dcar.cat,
          model: dcar.model         
        }   
      }
    }
    return out
  }
)

// get unique penalties
export const getPenalties = createSelector(
  [ getAllData ],
  ( data ) => getUnique( data, 'penalty', numSort )
)

// get fastest time of the day, returns the entire row
export const getFastest = createSelector(
  [ getAllData ],
  ( data ) => {
    let fastest = null;
    for( const d of data )
    {
      if ( !fastest || d.laptime < fastest.laptime ) 
      {  
        if ( !d.penalty && !d.comment ) 
        {
          fastest = d
        }
      }
    }
    return fastest;
  }
)

// get fastest times per car
export const getFastestPerCar = createSelector(
  [ getAllData ],
  ( data ) => {
    // strip invalid laptimes
    const valid_rows = data.filter( d => !d.penalty && !d.comment )
    // sort on laptime
    const sorted_rows = valid_rows.sort( ( a, b ) => a.laptime > b.laptime ? 1 : -1 )
    // cars for later use
    const cars = []
    // get fastest time per car
    const fast_rows = sorted_rows.filter( d => {
      if ( !cars.includes( d.car ) )
      {
        cars.push( d.car )
        return d
      }
    } )
    return fast_rows
  }
)
