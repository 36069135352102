import React from 'react'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { createRoot } from 'react-dom/client';

// add redux
import { Provider } from 'react-redux'
import { store, persistor } from './redux/store'
import { PersistGate } from 'redux-persist/integration/react'

const root = createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>

      { /* Redux provider */ }
      <Provider store={ store }>

        { /* Wait for store to be persisted */ }
        <PersistGate loading={ null } persistor={ persistor }>

          { /* Root browserRouter */ }
          <BrowserRouter>

            { /* The actual app */ }
            <App />

          </BrowserRouter>

        </PersistGate>

      </Provider>
    
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
