import CarLogo from './CarLogo'
import Icon from './Icon'

import { useSelector } from 'react-redux'
import { getFastestPerCar } from '../redux/data/selectors' 
//import { getYou } from './redux/options/selectors' 

const ScatterChart = ( props ) =>
{
  let {
    x = 0,
    y = 0,
    className = '',
    row = null
  } = props

  // user car number
  //const you = useSelector( getYou )
  const you = '1306'
  // get fastest time per car, sorted on laptime
  const fastest = useSelector( getFastestPerCar )
  // extract row ids
  const fastest_ids = fastest.map( f => f.id )  

  if ( !row ) return null

  const style = {
    left: x + 'px',
    top: y + 'px',
  }

  className += ' chart-tooltip'

  if ( String( row.car ) === String( you ) )
  {
    // your car
    className += ' you'
  }

  if ( row.penalty > 0 || row.comment !== '')
  {
    // orange for invalid laps
    className += ' invalid'
  }  
  else if ( fastest_ids.indexOf( row.id ) === 0 )
  {
    // purple for fastest lap
    className += ' fastest'
  }
  else if ( fastest_ids.indexOf( row.id ) > 0 )
  {
    // green for fast laps
    className += ' fast'
  }

  return (
    <div className={ className } style={ style }>
      <div className="car"><CarLogo brand={ row.model } />{ row.car }</div>
      <div className="time"><Icon icon="stopwatch"/>{ row.laptime }</div>
  </div>
  )
}

export default ScatterChart