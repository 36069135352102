import React from 'react'
import { Modal, Button, } from 'rsuite'
import Icon from './Icon'
import { useNavigate, useLocation } from 'react-router'
import { useSelector } from 'react-redux'
import { getData } from '../redux/data/selectors'
import SortTable from './SortTable'
import ScatterChart from '../components/ScatterChart'
import CarLogo from './CarLogo';

const CarModal = ( props ) =>
{
  const {
    show = true,
    data = null,
    rows = 5,
    ...rest
  } = props

  const all_data = useSelector( getData )
  const navigate = useNavigate ()
  const location = useLocation()

  // no use continuing if there's no data
  if ( !data ) return null

  // go to url
  const goTo = url => navigate( url )

  // sort on laptime
  const sortFast = ( a, b ) => a.laptime > b.laptime 

  // add row classname
  const rowClassName = ( row, className ) => {
    // mark the fastest row
    className += row.id === fastest_row.id ? ' fast' : ''
    // hilit the clicked row
    className += row.id === data.id ? ' hilite' : ''
    return className.replace( 'active', '' )
  }

  // get data for this car
  const car_data = all_data.filter( d => d.car === data.car ).sort( sortFast )
  // remove invalid laps
  const valid_data = car_data.filter( d => d.comment === '' && d.penalty === 0 )
  // use all data if there's not enough valid data
  const display_data = valid_data.length < rows ? car_data : valid_data
  // limit to 5 rows ( or less )
  let sliced_data = display_data.slice( 0, Math.min( rows, display_data.length ) )
  // is the clicked row present
  const active_row = sliced_data.filter( d => d.id === data.id )
  // the fastest row should be the first one
  const fastest_row = sliced_data[ 0 ]

  // which page are we on?
  const isGroupPage = location.pathname.indexOf( '/group/' ) === 0
  const isCarPage = location.pathname.indexOf( '/car/' ) === 0

  // calculate number of cones
  const cones = car_data.reduce( ( total, row ) => {
    // take missing / empty penalty into account
    if ( row.hasOwnProperty( 'penalty' ) && row.penalty > 0 )
    {
      return total + row.penalty
    }
    return total
  }, 0 ) / 5

  // insert and reorder
  if ( !active_row.length )
  {
    // remove last / slowest time
    sliced_data.pop()
    // insert clicked data
    sliced_data.push( data )
    // reorder
    sliced_data = sliced_data.sort( sortFast )
  }

  // modify display columns
  const column_options = {
    'pos': false,
    'cat': false,
    'car': false
  }

  // render the modal
  return (
    <Modal open={ data && show } backdrop={ true } { ...rest } className="WimedoApp car-modal">
      <Modal.Header>
        <Modal.Title>
          <CarLogo brand={ data.model }/>
          <div className="num">{ data.car }</div>
          <div className="model">{ data.model }</div>
          <Icon icon="flag"/>
          <div className="laps">{ car_data.length }</div>
          <Icon icon="cone"/>
          <div className="cones">{ cones }</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ScatterChart rows={ car_data } height="50px"/>
        <SortTable 
          data={ sliced_data }
          context="carmodal" 
          column_options={ column_options }
          clickRow={ false }
          columnSort={ false }
          rowClassName={ rowClassName }
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={ () => goTo( '/group/' + data.cat ) } disabled={ isGroupPage }><Icon icon="hashtag"/> Group details</Button>            
        <Button onClick={ () => goTo( '/car/' + data.car ) } disabled={ isCarPage }><Icon icon="car"/>  Car details</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default CarModal