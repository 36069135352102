const DataTableRow = ( props ) =>
{
  let {
    // row data
    row = {},
    // column value transformation method
    colValue = null,
    // css classname
    className = '',
    // list of columns to put in the row
    columns = [],
    ...rest
  } = props  

  return (
    <tr key={ row.id } className={ className.trim() } {...rest } >
      {
        columns.map( ( column, c ) => {
          const value = row.hasOwnProperty( column.name ) ? row[ column.name ] : ''
          return (
            <td key={ `col-${c}` } data-name={ column.name } data-value={ value }><div>{ colValue ? colValue( column, value, row ) : value }</div></td>
          )
        } )
      }
    </tr>
  )
}

export default DataTableRow