import React, { useEffect } from 'react'

// redux
import { useSelector, useDispatch } from 'react-redux'
import { getYou, getFastest, getNotificationOptions } from '../redux/options/selectors' 
import { getData } from '../redux/data/selectors' 
import { setOption } from '../redux/options/actions' 
import groupTypes from '../redux/options/groups'

// assets
import { Notification, toaster } from 'rsuite'
import Icon from './Icon'
import CarLogo from './CarLogo'
import { laptime2ms, ms2laptime } from '../tools/Time'
import { notificationTime, notificationsMax } from '../config'

// is this row regarding you?
const isYou = ( car, you ) => String( car ) === String( you )

/**
 * Show a React and Web notification
 * @param {*} props 
 */
const showNotification = ( props ) =>
{
  const {
    you = 0,
    data = null,
    css = '',
    context = '',
    type = 'info',
    title = '',
    duration = notificationTime
  } = props

  let alert_body = []

  if ( data )
  {
    if ( typeof data === 'string' )
    {
      alert_body.push( <p>{data}</p> )
    }
    // previous data 
    else if ( data.prev )
    {
      const prev_laptime = laptime2ms( data.prev.laptime )

      if ( data.next )
      {
        const next_laptime = laptime2ms( data.next.laptime )
        const diff_laptime = prev_laptime - next_laptime
        const diff = ms2laptime( Math.abs( diff_laptime ) )
        const className = diff_laptime > 0 ? 'neg' : 'pos'
        const prefix =  diff_laptime > 0 ? '-' : '+'

        const default_icon = isYou( data.next.car, you ) 
          ? <Icon icon="avatar"/> 
          : <Icon icon="car"/>

        const icon = <CarLogo brand={ data.next.model } fallback={ default_icon } />;
        
        alert_body.push( 
          <React.Fragment>          
            <p className="laptime-new">{ data.next.laptime }</p>
            <p className="next">                            
              <div className="car">
               { icon }{ data.next.car } - { data.next.model }        
              </div>                            
              <div className={ `timediff ${ className }`}>{ prefix } { diff }</div>
            </p> 
          </React.Fragment>  
        )
      }

      const default_icon = isYou( data.next.car, you ) 
          ? <Icon icon="avatar"/> 
          : <Icon icon="car"/>
      
      const icon = <CarLogo brand={ data.prev.model } fallback={ default_icon } />;
    
      alert_body.push( 
        <p className="prev">
          <div className="car">
            { icon }{ data.prev.car } - { data.prev.model }                                  
          </div>
          <div className="laptime-old"><Icon icon="clock-o"/> { data.prev.laptime }</div>                         
        </p> 
      )
    }
    else if ( data.next )
    {
      const default_icon = isYou( data.next.car, you ) 
          ? <Icon icon="avatar"/> 
          : <Icon icon="car"/>

      const icon = <CarLogo brand={ data.next.model } fallback={ default_icon } />;

      alert_body.push( 
        <React.Fragment>
          <p className="laptime laptime-new">{ data.next.laptime }</p>
          <p className="car">
           { icon }{ data.next.car } - { data.next.model }
          </p>
        </React.Fragment>
      )
    }
  }

  let className =  `type-${type} context-${context} ${css}`
  if ( data.next && you === data.next.car ) className += ' you'

  const key = toaster.push( 
    <Notification header={ title } className={ className } style={ { '--duration' : duration + 'ms' } } closable>
      { alert_body }
    </Notification>,
    { 
      duration : duration,
      placement: 'bottomEnd' 
    }
  )

  return key;
}

/**
 * Check all rows to see if we need to show any notifications
 * @param {*} param0 
 */
const checkRowNotifications = ( props ) =>
{
  const { 
    rows, 
    fastest, 
    notify,
    you, 
    updateFastest = null, 
    updateNotify = null 
  } = props

  // to be filled later, these contain ojects of different faster times
  let faster_day = null,
      faster_group = null,
      faster_you = null,   
      new_you = null,
      updated = false,
      // object with all fast times to compare with
      faster = { ...fastest },
      // list of cars with notifications, prevents multiple notifications per car
      cars = [],
      // last row notification ID, to prevent multiple notifications for the same or previous row
      row_id = notify.last_row_id

  // get your group from one of the rows
  let group = ''
  if ( you && rows && rows.length )
  {
    const your_laps = rows.filter( row => isYou( row.car, you ) );
    if ( your_laps.length )
    {
      group = your_laps[ 0 ].cat
    }
  }

  // notifications to be shown
  const notifications = []

  // stop here if there are no rows
  if ( !rows || !Array.isArray( rows ) || !rows.length ) return

  // make sure newest rows are processed first
  rows.sort( ( a, b ) => b.id - a.id )

  // go over each row
  rows.forEach( row => 
  { 
    // skip this row if it's older than the last row notification
    if ( row.id <= notify.last_row_id ) return

    // get stuff from row
    const {
      car = '', 
      cat = '', 
      laptime = '', 
      lap = 0,
      model = ''
    } = row
    
    // check required fields
    if ( car && cat && laptime )
    {
      // rows should trigger ONE update; day OR group OR car
      let row_updated = false

      // keys for use in the faster object
      const fast_day = 'fast-day',
            fast_group = 'fast-group-' + cat,
            fast_car = 'fast-car-' + car,          
            car_lap = 'car-lap-' + car ,
            is_you = isYou( car, you ),
            is_invalid = row.comment !== '',
            is_penalty = row.penalty > 0,
            your_group = group && String( group ) === String( cat )
            
      // check fast day laptime FIRST
      if ( !faster.hasOwnProperty( fast_day ) || laptime < faster[ fast_day ].laptime )
      {
        // skip invalid laps
        if ( !is_penalty && !is_invalid )
        {
          const prev = fastest.hasOwnProperty( fast_day ) ? faster[ fast_day ] : null 
          const next = { car, laptime, model }
          faster_day = { prev, next }   
          faster[ fast_day ] = next
          updated = row_updated = true
        }
      }

      // check fast group laptime SECOND
      if ( !row_updated )
      {
        if ( !faster.hasOwnProperty( fast_group ) || laptime < faster[ fast_group ].laptime )
        {
          // skip invalid laps
          if ( !is_penalty && !is_invalid )
          {
            const prev = faster.hasOwnProperty( fast_group ) ? faster[ fast_group ] : null  
            const next = { car, laptime, model }   
            
            // check your group
            if ( your_group )
            {
              faster_group = { prev, next }
            }
        
            faster[ fast_group ] = next
            updated = row_updated = true
          }
        }
      }

      // check fast car laptime THIRD
      if ( !row_updated )
      {
        if ( !faster.hasOwnProperty( fast_car ) || laptime < faster[ fast_car ].laptime )
        {
          // skip invalid laps
          if ( !is_penalty && !is_invalid )
          {
            const prev = fastest.hasOwnProperty( fast_car ) ? faster[ fast_car ] : null     
            const next = { car, laptime, model } 
            if ( is_you )
            {
              faster_you = { prev, next }
            }
            faster[ fast_car ] = next
            updated = row_updated = true
          }
        }
      }

      // check new lap LAST, allow invalid laps here
      if ( !row_updated )
      {
        if ( !faster.hasOwnProperty( car_lap ) || lap > faster[ car_lap ] )
        {
          if ( is_you )
          {
            new_you = { prev: null, next: { car: you, laptime, model } }
          }
          faster[ car_lap ] = lap
          updated = row_updated = true
        }
      }

      // store the last row ID
      if ( row_updated && row.id > row_id )
      {        
        row_id = row.id
      }
    }
  } )

  // show faster day time notification
  if ( faster_day )
  {
    // check notification settings
    if ( ( notify.new_time || notify.day_time ) && isYou( faster_day.next.car, you ) )
    {
        notifications.push( {
          you: you,
          css: 'fast-day',
          context: 'day',
          type: 'success', 
          data: faster_day,
          title: "You've set fastest day time"
        } )
    }
    else if ( notify.day_time )
    {
      notifications.push( {
        you: you,
        css: 'fast-day',
        data: faster_day,
        context: 'day',
        // make it red if you previously had the fastest time of the day, otherwise make it orange
        type: faster_day.prev && isYou( faster_day.prev.car, you ) ? 'error' : 'warning',
        title: 'Fastest time of the day'
      } )
    }
  }

  if ( faster_group )
  {
    if ( ( notify.new_time || notify.group_time ) && isYou( faster_group.next.car, you ) )
    {
      notifications.push( {
        you: you,
        css: 'fast-group',
        context: 'group',
        type: 'success',
        data: faster_group,
        title: "You've set fastest group time"
      } )
    }
    else if ( notify.group_time )
    {
      notifications.push( {
        you: you,
        css: 'fast-group',
        title: 'Fastest group time',
        data: faster_group,
        context: 'group',
        type: faster_group.prev && isYou( faster_group.prev.car, you ) ? 'error' : 'warning',
      } )
    }
  }

  /*
    Notify either faster time OR new time, but not both
  */

  if ( faster_you )
  {
    if ( notify.new_time )
    {
      notifications.push( { 
        you: you,
        css: 'fast-you',
        data: faster_you,
        title: "You've set a faster time",
        context: 'you',
        type: 'success'
      } )
    }
  }
  else if ( new_you )
  {
    if ( notify.new_time )
    {
      notifications.push( { 
        you: you,
        class: 'new-you',
        data: new_you,
        title: "You've set a new time",
        context: 'you',
        type: 'warning'
      } )
    }
  }

  // maybe update state
  if ( updateFastest && updated )
  {
    updateFastest( faster )
  }

  // maybe update last notification ID
  if ( updateNotify && row_id )
  {
    updateNotify( { last_row_id: row_id } )
  }

  //  show the first 3 notification
  for( let i=0; i<Math.min( notificationsMax, notifications.length ); i++ )
  {
    // shortcut to the notification
    const n = notifications[ i ]
    const car = n.data.next ? n.data.next.car : ''
    // check if this car hasn't yet been notified
    if ( !car || !cars.includes( car ) )
    {
      // store car notification
      if ( car ) cars.push( car )

      // add brand
      // const d = getCarDetails( car )
      // if ( d ) n.brand = d.brand

      // show notification with a short delay
      setTimeout( () => showNotification( n ), ( i + 1 ) * 300 )
    }    
  }   
}

// e,pty component for receiving state
export const Notifications = ( props ) =>
{
  // redux
  const you = useSelector( getYou )
  const fastest = useSelector( getFastest )
  const notify = useSelector( getNotificationOptions )
  const rows = useSelector( getData )

  // dispatcher
  const dispatch = useDispatch()  
  // update faster time notification
  const updateFastest = ( faster ) => dispatch( setOption( groupTypes.FAST, faster ) )
  // update last notification ID
  const updateNotify = ( notify ) => dispatch( setOption( groupTypes.NOTIFY, notify ) )

  // check rows when they update
  useEffect( () => {

    checkRowNotifications( { rows, you, fastest, notify, updateFastest, updateNotify  } )

    // maybe close the last notifications on overflow
    const containers = document.getElementsByClassName( 'rs-notification' )        
    
    if ( containers.length )
    {
      const container = containers[ 0 ]
      
      // use an interval to remove elements until no notifications stick outside of the page
      // this also gives the browser some time to render before we call this check
      let i = setInterval( () =>  
      {
        // make sure we have notifications
        const notifications = container.getElementsByClassName( 'rs-notification-item-close' )

        if ( !notifications.length && container )
        {
          try
          {
            container.parentNode.removeChild( container );
          }
          catch( e )
          {
            // ignore it
          }
          clearInterval( i )
          return
        }
        // close the first notification or stop
        if ( container.scrollHeight > container.clientHeight || notifications.length > notificationsMax )
        {          
          notifications[ 0 ].click()
        }
        else
        {
          clearInterval( i )
        }
      }, 500 )   
    }
  }, [ rows ] )

  return null
}