import React, { useState, useEffect } from 'react'
import { Navbar, Nav, Button } from 'rsuite'
import { Routes, Route, Link } from 'react-router-dom'
import Icon from './components/Icon'
import axios from 'axios'

// assets
import Logo from './assets/wimedo-icon.png'

// redux
import { useSelector, useDispatch } from 'react-redux'
import { getYou, getAskedYou } from './redux/options/selectors' 
import { setOption } from './redux/options/actions' 
import { setData } from './redux/data/actions' 
import groupTypes from './redux/options/groups'

// styles
import 'rsuite/styles/index.less'
import './css/App.less' 

// pages
import HomePage from './pages/HomePage'
import GroupPage from './pages/GroupPage'
import CarPage from './pages/CarPage'

// components
import NavLink from './components/NavLink'
import OptionsDrawer from './components/OptionsDrawer'
import { Notifications } from './components/Notifications'
import { getData } from './redux/data/selectors'
import { apiUrl, pollDelayShort, pollDelayLong, maxRows } from './config'

// api url params
const apiParams = new URLSearchParams()

let timeoutId = 0
// last update value
let lastUpdate = ''

// load data from server
const requestData = () => 
{ 
  // add last update param
  if ( lastUpdate )
  {    
    apiParams.set( 'since', lastUpdate )
  }

  // return the axios result
  return axios.get( apiUrl + '?' + apiParams.toString() )
}

const App = () => {

  // state
  const [ showOptions, setShowOptions ] = useState( false )

  // redux
  const you = useSelector( getYou )
  const askedYou = useSelector( getAskedYou )
  const data = useSelector( getData )
  const dispatch = useDispatch()

  // reset last update when there is no data
  if ( !data )
  {
    lastUpdate = ''
  }
  
  // enqueue data for the next run
  const queueData = ( delay = 0 ) => {
    if ( !delay ) delay = pollDelayShort
    // reset interval
    if ( timeoutId ) clearTimeout( timeoutId );
    // plan the next data retrieval
    timeoutId = setTimeout( () => { loadData() }, delay )
  }

  // get last updated entry and remember the time
  const getLastUpdate = ( rows ) => 
  {
    for( const row of rows )
    {       
      // update lastUpdate time
      if ( row.hasOwnProperty( 'time' ) && row.time > lastUpdate )
      {
        lastUpdate = row.time
      } 
    }
  }
  
  // get and set data in state
  const loadData = () => { 
    let delay = pollDelayShort
    requestData()
      // set data on success
      .then( response => {
        // make sure we have a response and some data
        if ( response && response.data )
        {
          // we're expecting an array to be returned
          if ( Array.isArray( response.data.data ) )
          {
            // no need to update if we hava zero length array
            if ( response.data.data.length )
            {
              // get data rows
              let rows = response.data.data

              // limit the number of rows for debugging
              if ( maxRows )
              {
                // sort on time, oldest first
                rows.sort( ( a, b ) => a.time < b.time ? -1 : 1 )
                // limit the number of rows to process
                rows = rows.slice( 0, maxRows )
              }

              // get last update time
              getLastUpdate( rows )

              // update data
              dispatch( setData( rows ) )
            }
          }
          else
          {
            // no data at all? reset the last update time and try to load all data next time
            lastUpdate = ''
          }

          // slower polling when getting from cache
          if ( response.data.hasOwnProperty( 'method' ) && response.data.method.indexOf( 'cache' ) >= 0 )
          {
            delay = pollDelayLong
          }          
        }
        else
        {
          // no response at all? reset the last update time and try to load all data next time
          lastUpdate = ''
        }
      } )
      // ignore errors
      .catch( error => console.error( error ) )
      // always queue next request
      .then( () => queueData( delay ) )
  }

  // load data on init
  useEffect( () => {

    // set last update to 00:00:00 to force this effect to run only once
    if ( !lastUpdate )
    {
      lastUpdate = '00:00:00';

      // load data for the first time
      loadData()

      // reset fastest times
      dispatch( setOption( groupTypes.FAST, {} ) )

      // show you form ONCE
      if ( !you && !askedYou)
      {
        setShowOptions( true )
        dispatch( setOption( 'askedYou', true ) )
      }
    }
  }, [] )

  // show / hide options panel
  const onShowOptions = () => setShowOptions( true )
  const onHideOptions = () => setShowOptions( false )

  return (
      <div className="WimedoApp">
        
        <Navbar className="navbar" appearance="inverse">
          <Link to="/" className="logo">
          <img src={ Logo } alt="Wimedo logo" key="logo"/>
          </Link>
          <Navbar.Brand>            
            <Nav defaultActiveKey="home">              
              <NavLink icon="home" href="/" eventKey="home">Home</NavLink>
              <NavLink icon="hashtag" href="/group" eventKey="group">Group</NavLink>
              <NavLink icon="car" href="/car" eventKey="car">Car</NavLink>
            </Nav>
          </Navbar.Brand>      

          <Button
            onClick={ onShowOptions } 
            className="you"
          ><Icon icon="avatar" className={ you ? 'active' : '' } /> { you ? you : '0000' }</Button>

        </Navbar>

        <div className="main-content">
          <Routes>
            <Route exact={ true } path="/" element={ <HomePage/> } />
            <Route path="/group/:group?" element={ <GroupPage/> } />
            <Route path="/car/:car?" element={ <CarPage/> } />
          </Routes>  
        </div>   
        
        <Notifications/>

        <OptionsDrawer show={ showOptions } onClose={ onHideOptions } />

      </div>
  )
}

export default App
