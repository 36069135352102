import React from 'react'
import { Drawer, Form } from 'rsuite'
import Icon from './Icon'

// components
import YouForm from './YouForm'
import NotifyOptions from './NotifyOptions'
import DisplayOptions from './DisplayOptions'


const OptionsDrawer = ( { show = false, onClose } ) =>
{ 
  // fix double intro animation
  const onEntered = ( e ) => {
    e.classList.remove( 'slide-in', 'animated' )
  }

  return (
    <Drawer open={ show } onClose={ onClose } className="options-drawer" onEntered={ onEntered }>
      <Drawer.Header>
        <Drawer.Title><Icon icon="avatar"/> Your preferences</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <Form>
          <Form.Group className="you">
            <Form.ControlLabel><Icon icon="car"/> Registration number</Form.ControlLabel>
            <YouForm onClose={ onClose }/>
          </Form.Group>
          <Form.Group className="display">
            <Form.ControlLabel><Icon icon="eye"/> Display charts &amp; graphs</Form.ControlLabel>
            <DisplayOptions/>           
          </Form.Group>
          <Form.Group className="notifications">
            <Form.ControlLabel><Icon icon="bell"/> In-app notifications</Form.ControlLabel>
            <NotifyOptions/>            
          </Form.Group>
          <Form.Group className="credits">
            <Icon icon="rebel"/>App by <a href="mailto:sanderfalise@gmail.com?subject=Wimedo app" target="_blank" rel="noreferrer">Sander Falise</a> &copy; 2021 - { new Date().getFullYear() } 
          </Form.Group>
        </Form>        
      </Drawer.Body>
    </Drawer>
  )
}

export default OptionsDrawer