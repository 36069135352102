import React from 'react'

// components
import FilterDropdown from './FilterDropdown'
import { Dropdown } from 'rsuite'

// redux
import { useSelector, useDispatch } from 'react-redux'
import { getOptions, getContextOptions, getYou } from '../redux/options/selectors' 
import { setOption } from '../redux/options/actions' 
import { getGroups } from '../redux/data/selectors'
import Icon from './Icon'

const GroupDropdown = ( { showAll = true, context = 'filters' } ) =>
{
  // get all options
  const options = useSelector( getOptions )     
  // try to get filters from context
  const { group = '' } = getContextOptions( options, context )
  // other selectors
  const groups = useSelector( getGroups )
  const you = useSelector( getYou )

  // dispatchers  
  const dispatch = useDispatch()
  const onSelect = g => dispatch( setOption( 'group', g, context ) )

  // get the group name from the number / key
  const getGroupName = ( group = '' ) =>
  {
    // if it's a number, prefix 'cat
    if ( !isNaN( parseInt( group ) ) )
    {
      return `Group ${group}`
    }
    return group
  }

  // render the dropdown
  return (
    <FilterDropdown
      className="filter-group"
      title={ group ? getGroupName( group ) : 'Group' }
      icon="hashtag"
      selectAll = 'All groups'
      onSelect={ onSelect }
      selected={ group }
      showAll={ showAll }
    >
      { groups.map( i => (
          <Dropdown.Item key={ `key-${i}` } eventKey={ i } className={ you && String( you ).indexOf( i ) === 0 ? 'you' : '' }>
          { getGroupName( i ) }
          { you && String( you ).indexOf( i ) === 0 && <Icon icon="avatar" key="avatar" /> }
          </Dropdown.Item> 
        ) ) }
    </FilterDropdown>
  )
}

export default GroupDropdown